import React from 'react';
import Fade from 'react-reveal/Fade';
import SquiggleTop from '../../../images/business/events/mixer/squiggletop.png';
import ImgCheck from '../../../images/main/checkmark.png';
import ImgCounsellors from '../../../images/main/counsellors.png';

const list = [
  'Glutenberg tasting sessions.',
  'Delicious finger food.',
  'Giveaways from participating health and wellness vendors.',
  'Micro demonstrations.',
];

const OfferingSection = () => (
  <div className="main-page">
    <div className="main-offerings">
      <Fade delay={300} duration={1500}>
        <div className="mo-contents">
          <div className="mo-item">
            <h3 className="mo-title">For your enjoyment, we will be offering:</h3>
            <div className="mo-list">
              <ul>
                {list.map((item, i) => (
                  <li key={`key-${i + 1}`}>
                    <div className="mo-bullet">
                      <img src={ImgCheck} alt="check" />
                    </div>
                    <div className="mo-text">{item}</div>
                  </li>
              ))}
                <li key="keynote">
                  <div className="mo-bullet">
                    <img src={ImgCheck} alt="check" />
                  </div>
                  <div className="mo-text">
                    <span className="blue">Keynote Speaker: Dr. Karen Katchen</span>, Registered Psychologist,
                  Executive Coach & Mother to 3 successful tech startup founders.
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Fade>
      <Fade delay={300} duration={1500}>
        <div className="mo-image">
          <div className="msc-item">
            <img src={ImgCounsellors} alt="party" />
          </div>
        </div>
      </Fade>
    </div>
    <Fade delay={300} duration={1500}>
      <div className="mo-image">
        <div className="proceeds">
          <h1 className="title">
          Proceeds to be donated in support of the Center for Addiction and Mental Health.
          </h1>
        </div>
      </div>
    </Fade>
    <img src={SquiggleTop} id="squiggletop" alt="squiggle" />
  </div>
);

OfferingSection.propTypes = {};

export default OfferingSection;
