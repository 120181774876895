import React, { Component } from 'react';
import VendorModal from 'react-modal';
import Fade from 'react-reveal/Fade';
import CampFuel from '../../../images/business/events/mixer/logos/CampFuel.png';
import CampFuelHover from '../../../images/business/events/mixer/logos/CampFuelHover.png';
import CampFuelHeadshot from '../../../images/business/events/mixer/bios/CampFuelHeadshot.png';
import CampFuelLogo from '../../../images/business/events/mixer/bios/CampFuelLogo.png';
import Epicater from '../../../images/business/events/mixer/logos/Epicater.png';
import EpicaterHover from '../../../images/business/events/mixer/logos/EpicaterHover.png';
import EpicaterHeadshot from '../../../images/business/events/mixer/bios/EpicaterHeadshot.png';
import EpicaterLogo from '../../../images/business/events/mixer/bios/EpicaterLogo.png';
import FundOfPlay from '../../../images/business/events/mixer/logos/FundamentalsOfPlay.png';
import FundOfPlayHover from '../../../images/business/events/mixer/logos/FundamentalsOfPlayHover.png';
import FundOfPlayHeadshot from '../../../images/business/events/mixer/bios/FundamentalsHeadshot.png';
import FundOfPlayLogo from '../../../images/business/events/mixer/bios/FundamentalsLogo.png';
import Hoame from '../../../images/business/events/mixer/logos/Hoame.png';
import HoameHover from '../../../images/business/events/mixer/logos/HoameHover.png';
import HoameHeadshot from '../../../images/business/events/mixer/bios/HoameHeadshot.png';
import HoameLogo from '../../../images/business/events/mixer/bios/HoameLogo.png';
import Wellth from '../../../images/business/events/mixer/logos/Wellth.png';
import WellthHover from '../../../images/business/events/mixer/logos/WellthHover.png';
import WellthHeadshot from '../../../images/business/events/mixer/bios/WellthHeadshot.png';
import WellthLogo from '../../../images/business/events/mixer/bios/WellthLogo.png';
import MindfulSnacks from '../../../images/business/events/mixer/logos/MindfulSnacks.png';
import MindfulSnacksHover from '../../../images/business/events/mixer/logos/MindfulSnacksHover.png';
import MindfulSnacksHeadshot from '../../../images/business/events/mixer/bios/MindfulSnacksHeadshot.png';
import MindfulSnacksLogo from '../../../images/business/events/mixer/bios/MindfulSnacksLogo.png';
import Calm from '../../../images/business/events/mixer/logos/Calm.png';
import CalmHover from '../../../images/business/events/mixer/logos/CalmHover.png';
import CalmHeadshot from '../../../images/business/events/mixer/bios/CalmHeadshot.png';
import CalmLogo from '../../../images/business/events/mixer/bios/CalmLogo.png';
import Sprout from '../../../images/business/events/mixer/logos/Sprout.png';
import SproutHover from '../../../images/business/events/mixer/logos/SproutHover.png';
import SproutHeadshot from '../../../images/business/events/mixer/bios/SproutHeadshot.png';
import SproutLogo from '../../../images/business/events/mixer/bios/SproutLogo.png';
import DrLaurenStethem from '../../../images/business/events/mixer/logos/DrLaurenStethem.png';
import DrLaurenStethemHover from '../../../images/business/events/mixer/logos/DrLaurenStethemHover.png';
import DrLaurenStethemHeadshot from '../../../images/business/events/mixer/bios/DrLaurenStethemHeadshot.png';
import DrLaurenStethemLogo from '../../../images/business/events/mixer/bios/DrLaurenStethemLogo.png';
import MeFirstNow from '../../../images/business/events/mixer/logos/MeFirstNow.png';
import MeFirstNowHover from '../../../images/business/events/mixer/logos/MeFirstNowHover.png';
import MeFirstNowHeadshot from '../../../images/business/events/mixer/bios/MeFirstHeadshot.png';
import MeFirstNowLogo from '../../../images/business/events/mixer/bios/MeFirstLogo.png';
import SquiggleBottom from '../../../images/business/events/mixer/squigglebottom.png';
import CloseIcon from '../../../images/business/events/mixer/bios/close.svg';

const vendorList = [
  {
    name: 'Camp Fuel',
    img: CampFuel,
    imgHover: CampFuelHover,
    imgHeadshot: CampFuelHeadshot,
    imgLogo: CampFuelLogo,
    widthLogo: 124,
    desc: (
      <div>
        <p>
          I started Camp Fuel, an all-inclusive fitness weekend for adults, because when I was
          twelve years old I had my first overnight camp experience and it changed me. There were
          cabins, bunk beds, high ropes, canoeing and a dining hall. I wasn’t a social butterfly
          growing up so making friends was hard, and going to camp was a bit scary. But once I got
          there, I realized that camp was inclusive and encouraging – the counsellors made sure of
          it. I was able to be my authentic self and for the first time I felt like I belonged.
          Now, more than twenty years later I have the opportunity to bring that same feeling to
          adults. At Camp Fuel we take care of everything, you just show up with your sleeping bag
          and we handle the rest! All food & drinks (including alcohol in the evening) there are
          20+ activities a day to choose from! Bootcamp, Yoga, Archery, kayaking, SUP, PLAYshops,
          meditation and much more!
        </p>
      </div>
    ),
  },
  {
    name: 'Epicater',
    img: Epicater,
    imgHover: EpicaterHover,
    imgHeadshot: EpicaterHeadshot,
    imgLogo: EpicaterLogo,
    widthLogo: 265,
    desc: (
      <div>
        <p>
          Companies spend a fortune trying to hire and keep talent, foster a healthy office
          culture, and encourage collaboration amongst their staff. Epicater provides nutritious,
          family-style staff lunches in order to help build a more welcoming and inclusive
          workplace environment. We tailor meal plans that accommodate most dietary restrictions
          and enable employees to connect with each other over lunch from local vendors.
          By leveraging these local vendors, we are able to produce and deliver meals, on budget,
          on time, and on-point.
        </p>
      </div>
    ),
  },
  {
    name: 'fundamentals of play',
    img: FundOfPlay,
    imgHover: FundOfPlayHover,
    imgHeadshot: FundOfPlayHeadshot,
    imgLogo: FundOfPlayLogo,
    widthLogo: 267,
    desc: (
      <div>
        <p>
          FUNdamentals of Play maximizes teams&apos; potential through the power of PLAY!<br />
          PLAYshops are fun and engaging workshops that focus on the main factors that allow
          teams to thrive:positivity, teamwork and creativity. We use improv games, positive
          psychology and art to connect employees to concepts of mental health and workplace
          happiness. They are perfect for Lunch N Learns, team meetings, conferences/retreats
          or as monthly wellness workshops.
        </p>
        <br />
        <p>
          Why Play?<br />
          ● Play is essential to creativity, innovation, health, and ultimately HAPPINESS<br />
          ● Play adds joy to life, relieve stress and supercharges LEARNING<br />
          ● Play allows teams to LET LOOSE and drop their ‘work front&apos;<br />
          ● Play connects people organically &amp; authentically to create GREAT TEAMS<br />
          ● Incorporating play into business makes teams more PRODUCTIVE<br />
        </p>
      </div>
    ),
  },
  {
    name: 'hoame',
    img: Hoame,
    imgHover: HoameHover,
    imgHeadshot: HoameHeadshot,
    imgLogo: HoameLogo,
    widthLogo: 284,
    desc: (
      <div>
        <p>
          HoAme is Toronto’s one stop destination for enhanced mind and body services. Enjoy our
          curated selection of seated guided meditation, mindful movement and higher intensity
          meditative classes. Discover our breathtakingly immersive light and dark themed studios,
          Himalayan rock salt cave, infrared sauna, customized workshops/specialty events,
          corporate and private retreats and so much more.
        </p>
      </div>
    ),
  },
  {
    name: 'wellth',
    img: Wellth,
    imgHover: WellthHover,
    imgHeadshot: WellthHeadshot,
    imgLogo: WellthLogo,
    widthLogo: 176,
    desc: (
      <div>
        <p>Wealth.</p>
        <p>
          It’s a loaded word that needs a new definition. It’s no longer just a bank statement;
          it’s a state of being. It’s not about capital gains, it’s about gaining a broader
          perspective on what success truly means to your bottom line. While money is the means,
          it’s really about planning your finances to get you what money can’t buy. Experiences.
          Freedom. Wellness.
        </p>
        <p>
          It’s time to get financially healthy. Personally understand your personal finances.
          Learn to save for the future, or a five-star getaway. Or both!
        </p>
        <p>That’s wellthy living. And we are the Wellth Company.</p>
        <p>
          An empowered (and empowering) financial services advocate, we provide the millennial
          workforce with the information and tools needed to turn their dreams into a reality
          worth investing in.
        </p>
        <p>
          Through group workshops and one-on-one coaching, we show young professionals how they
          can enjoy their money responsibly and achieve success, however they define it. From cash
          flow planning to investing intentionally to minimizing taxes, we’ve got it covered.
        </p>
        <p>
          Our financial education and advisory services are designed for the way millennials work
          and communicate. We talk with you, not at you. We work beside you, not over you.
          And most importantly, we measure success by how much fun we’re having.
        </p>
        <p>We can’t wait to work with you!</p>
      </div>
    ),
  },
  {
    name: 'mindful snacks',
    img: MindfulSnacks,
    imgHover: MindfulSnacksHover,
    imgHeadshot: MindfulSnacksHeadshot,
    imgLogo: MindfulSnacksLogo,
    widthLogo: 100,
    desc: (
      <div>
        <p>
          Mindful snacks started off as a revolutionary idea because we would hear office managers
          tell stories about having to take matters into their own hands when it came to shopping,
          stocking, and cleaning up. We also saw first hand the unhealthy, extremely processed
          snack options being offered to employees. Since then, we have set out to revolutionize
          the idea of what an office snack provider should do for you and your office.
        </p>
        <p>
          Mindful Snacks is here to revolutionize your idea of what an office snack provider
          should do for you and your office.
        </p>
        <p>
          Mindful Snacks specializes in workplace wellness through boosting employee benefits with
          healthy snacks and beverages in the office. We have our full service team deliver,
          set-up and display snacks and drinks, clean your kitchen area, track and record
          inventory, and manage re-orders.
        </p>
      </div>
    ),
  },
  {
    name: 'calm',
    img: Calm,
    imgHover: CalmHover,
    imgHeadshot: CalmHeadshot,
    imgLogo: CalmLogo,
    widthLogo: 100,
    desc: (
      <div>
        <p>
          WellCalm was founded in April 2016 in Toronto, Canada with the mission to
          re-shape the delivery of therapeutic services by making it accessible,
          flexible and convenient for both our clients and therapists.
        </p>
        <p>
          WellCalm is a hybrid community of tech and wellness professionals
          including over 100 registered massage therapists, chiropractors and
          physiotherapists.
        </p>
        <p>
          WellCalm provides on-demand therapeutic services to our clients from the
          comfort of their home, of office and hotel.
        </p>
      </div>
    ),
  },
  {
    name: 'sprout',
    img: Sprout,
    imgHover: SproutHover,
    imgHeadshot: SproutHeadshot,
    imgLogo: SproutLogo,
    widthLogo: 183,
    desc: (
      <div>
        <p>
          Sprout is the leading Wellbeing Engagement Platform, used by progressive companies
          to improve the health of their employees and their business. Sprout creates work culture
          that enables individuals to lead happier, healthier lives, while empowering employers
          to effectively manage and measure wellness outcomes.
        </p>
      </div>
    ),
  },
  {
    name: 'dr. lauren stethem',
    img: DrLaurenStethem,
    imgHover: DrLaurenStethemHover,
    imgHeadshot: DrLaurenStethemHeadshot,
    imgLogo: DrLaurenStethemLogo,
    widthLogo: 306,
    desc: (
      <div>
        <p>
          Dr. Lauren Stethem is the owner and founder of Dr. Lauren Stethem Chiropractic,
          which offers on-site and mobile Chiropractic services to businesses and community groups
          in Toronto and the GTA.
        </p>
        <p>
          Originally from Winnipeg Manitoba; Dr. Stethem attended the University of Winnipeg where
          she graduated with a Bachelor of Psychology. Following that Dr.Stethem spent the next
          two years teaching and living abroad in Bangkok, Thailand; which was where she discovered
          her passion for medicine, healing, and Chiropractic care.
        </p>
        <p>
          In an effort to follow this new direction Lauren sought out the premier chiropractic
          school in the world: The Canadian Memorial Chiropractic College, where she received her
          Doctor of Chiropractic, graduating with Clinical Honors in 2015. Interning at the
          Sherbourne Health Centre in downtown Toronto and The Canadian Memorial Chiropractic
          College Campus Clinic.
        </p>
        <p>
          Dr. Stethem is certified in the Webster Technique. This gives her the expertise in care
          and proper mechanics of the pregnant mother. She is also a member of International
          Pediatric Chiropractic Association which allows her to focus on prenatal, postnatal,
          and pediatric care.
        </p>
        <p>
          Dr. Stethem’s current family practice is located at “Focused on Health Chiropractic”
          at Yonge and Eglinton in Midtown Toronto. Seeing so many patients come in day after day
          with similar stress injuries due to poor posture and ergonomics in the workplace has
          inspired Lauren to create Dr. Lauren Stethem Chiropractic which offers: On-site
          Chiropractic care, Biomechanical Assessments, Custom Orthotic Fitting, and Ergonomic
          Assessments.
        </p>
        <p>
          The main clientele of Dr. Lauren Stethem Chiropractic are companies and organizations
          that desire a healthier, more efficient and productive workplace. Through Ergonomic and
          Biomechanical Assessments workstations and workplaces can be adjusted to be healthier
          for the spine and body; improving the working environment. On-site Chiropractic Care
          and Orthotic Fitting decreases injury to workforce and eliminates the need to use sick
          days or time away from work to seek treatment.
        </p>
      </div>
    ),
  },
  {
    name: 'me first now',
    img: MeFirstNow,
    imgHover: MeFirstNowHover,
    imgHeadshot: MeFirstNowHeadshot,
    imgLogo: MeFirstNowLogo,
    widthLogo: 356,
    desc: (
      <div>
        <p>
          Donna Hall is a Coach, Energy Specialist, Consultant and Speaker and the name behind
          MeFirstNow, a company specializing in helping clients who have forgotten their dreams
          to understand what they are here to be, do and have and to go for it!
        </p>
        <p>
          Donna has a unique ability to read energy through chakras to help clients identify
          unique aspects about themselves, as well as find out what is getting in the way of
          unlocking and living their dreams; leading clients to experience greater freedom,
          clarity and happiness.
        </p>
        <p>
          Donna will be offering free Intuitive Scans at the event. Be sure to drop-by and see her.
        </p>
      </div>
    ),
  },
];

VendorModal.setAppElement('#___gatsby');

class VendorsSection extends Component {
  constructor() {
    super();

    this.state = {
      showModal: false,
      vendorIndex: 0,
    };
  }

  handleOpenModal = index => {
    this.setState({
      showModal: true,
      vendorIndex: index,
    });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { showModal, vendorIndex } = this.state;
    return (
      <div>
        <Fade delay={300} duration={1500}>
          <div className="vendors-container">
            <h3>Meet the Toronto Wellness Community.</h3>
            <div className="row">
              {
                vendorList.map((vendor, index) => (
                  // eslint-disable-next-line
                  <div
                    key={`key-${index + 1}`}
                    className="vendor-img"
                    onClick={() => this.handleOpenModal(index)}
                  >
                    <img src={vendor.imgHover} className="vendor-opaque" alt={vendor.name} />
                    <img src={vendor.img} className="vendor-trans" alt={vendor.name} />
                  </div>
                ))
              }
              <div className="vendor-img placeholder-green" />
              <div className="vendor-img placeholder-blue" />
            </div>
          </div>
        </Fade>
        <img src={SquiggleBottom} id="squigglebottom" alt="squiggle" style={{ position: 'relative', zIndex: 2 }} />
        <VendorModal
          onRequestClose={this.handleCloseModal}
          isOpen={showModal}
          className="vendor-modal"
          overlayClassName="vendor-modal-overlay"
        >
          <div className="vm-contents">
            <div className="vmc-close">
              {/* eslint-disable-next-line */}
              <img src={CloseIcon} onClick={this.handleCloseModal} alt="close" />
            </div>
            <div className="vmc-bio">
              <div className="vmcb-headshot">
                <img src={vendorList[vendorIndex].imgHeadshot} alt={vendorList[vendorIndex].name} />
              </div>
              <div className="vmcb-logo-desc">
                <div className="vmcbl-logo">
                  <img
                    src={vendorList[vendorIndex].imgLogo}
                    width={vendorList[vendorIndex].widthLogo}
                    alt={vendorList[vendorIndex].name}
                  />
                </div>
                <div className="vmcbl-desc">
                  {vendorList[vendorIndex].desc}
                </div>
              </div>
            </div>
          </div>
        </VendorModal>
      </div>
    );
  }
}

export default VendorsSection;
