import React from 'react';
import InkblotBusiness from '../../../images/InkBusinessWhite.png';
import Uberflip from '../../../images/business/events/mixer/Uberflip.png';
import Camh from '../../../images/business/events/mixer/Camh.png';
import Glutenburg from '../../../images/business/events/mixer/Glutenburg.png';

const IntroSection = () => (
  <div className="events-intro">
    <div className="mi-title" style={{ marginTop: 20 }}>
      <div className="ink-logo">
        <img id="inkbusiness" src={InkblotBusiness} className="animated fadeIn" alt="inkblot for business" />
      </div>
      <h4 className="animated fadeIn delay-1s" style={{ marginBottom: 35 }}>presents</h4>
      <h2 className="animated fadeIn delay-1s" style={{ marginBottom: 55 }}>The 1st Annual Employee Wellness Marketplace & Mixer</h2>
    </div>
    <div className="sponsors">
      <div className="sponsor">
        <h4 className="animated fadeIn delay-1s">Graciously hosted by</h4>
        <img src={Uberflip} className="animated fadeIn delay-1s" id="uberflip" alt="Uberflip" />
      </div>
      <div className="sponsor">
        <h4 className="animated fadeIn delay-1s">In support of</h4>
        <img src={Camh} className="animated fadeIn delay-1s" id="camh" alt="Cam H" />
      </div>
      <div className="sponsor">
        <h4 className="animated fadeIn delay-1s" style={{ marginBottom: 25 }}>Proudly sponsored by</h4>
        <img src={Glutenburg} className="animated fadeIn delay-1s" id="glutenburg" alt="Glutenburg" />
      </div>
    </div>
    <div className="ei-register">
      <a href="https://www.eventbrite.ca/e/employee-wellness-marketplace-mixer-tickets-51488140414">
        <button className="light-blue-button">Register</button>
      </a>
    </div>
  </div>
);

IntroSection.propTypes = {};

export default IntroSection;
