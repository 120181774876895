import React from 'react';
import Fade from 'react-reveal/Fade';
import KarenK from '../../../images/business/events/mixer/KarenK.png';
import KarenKLogo from '../../../images/business/events/mixer/KarenKLogo.png';

const KeynoteSection = () => (
  <div className="keynote" style={{ position: 'relative', zIndex: 1 }}>
    <Fade delay={300} duration={1500}>
      <div className="keynote-section">
        <h4>Keynote Speaker: Dr. Karen Katchen</h4>
        <div className="ks-logo-mobile">
          <img src={KarenKLogo} alt="Katchen Consulting" />
        </div>
        <div className="keynote-info">
          <img id="karenk" src={KarenK} alt="Karen Katchen" />
          <div className="keynote-bio">
            <img id="karenklogo" src={KarenKLogo} alt="Katchen Consulting" />
            <p>
              Dr. Karen Katchen is a Registered Clinical and Counselling Psychologist
              as well as an Executive Coach.  She has been active in the field of Mental
              Health Promotion for many years.  As Founder of Mental Health Tune Up,
              she received awards for bringing together industry, organizations, celebrities,
              Psychologists, media, grassroot and government services.
            </p>
            <p>
              As a Psychologist, Dr. Katchen brings a depth of knowledge and experience to
              her private practice with people who are experiencing stress, life, work and
              relationship transitions, or more acute mental health difficulties such as anxiety,
              panic or depression.
            </p>
            <p id="underphoto">
              As a Coach, Dr. Katchen works with executives, teams, and organizations on
              professional and personal development offering a clear understanding of the pressures
              that teams face in building rapidly scaling tech companies.  Whether working with
              Founders, C-Suite, or employees, she creates unique programs supporting:
              team-building; social and emotional intelligence (EQ); resiliency for optimal
              performance; communications; leadership development; and conflict resolution.
            </p>
            <p>
              Her unique approach to EQ development maps the individual and collective EQ strengths
              and weaknesses of your executive team and creates a training program to help your team
              strengthen their EQ muscles and exponentially improve performance and ROI.
            </p>
          </div>

        </div>
      </div>
    </Fade>
  </div>
);

KeynoteSection.propTypes = {};

export default KeynoteSection;
