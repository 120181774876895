import React from 'react';
import Fade from 'react-reveal/Fade';

const RegisterSection = () => (
  <Fade duration={1500} delay={300}>
    <div className="section-register">
      <div>
        <p id="register">
          Ready to bring the Toronto Wellness Community to your workspace?
        </p>
      </div>
      <div>
        <a href="https://www.eventbrite.ca/e/employee-wellness-marketplace-mixer-tickets-51488140414">
          <button className="btn-dark-blue-blue">REGISTER</button>
        </a>
      </div>
    </div>
  </Fade>
);

RegisterSection.propTypes = {};

export default RegisterSection;
