import React from 'react';
import Fade from 'react-reveal/Fade';

const DescriptionSection = () => (
  <Fade duration={1500} delay={300}>
    <div className="desc-section">
      <p style={{ maxWidth: '100%' }}><span className="blue">On November 14th from 6-9pm</span>, we invite you join us at
        <span className="ds-blue"> Uberflip</span> to discover local health and wellness resources
        that improve workplace culture, employee retention and, ultimately, the bottom line.
      </p>
    </div>
  </Fade>
);

DescriptionSection.propTypes = {};

export default DescriptionSection;
