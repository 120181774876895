import React from 'react';

import HeroSection from '../../pagePartials/business/events/HeroSection';
import DescriptionSection from '../../pagePartials/business/events/DescriptionSection';
import OfferingSection from '../../pagePartials/business/events/OfferingSection';
import VendorsSection from '../../pagePartials/business/events/VendorsSection';
import KeynoteSection from '../../pagePartials/business/events/KeynoteSection';
import RegisterSection from '../../pagePartials/business/events/RegisterSection';

const Business = () => (
  <div className="business-page">
    <HeroSection />
    <DescriptionSection />
    <OfferingSection />
    <VendorsSection />
    <KeynoteSection />
    <RegisterSection />
  </div>
);

export default Business;
